<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "AGREGAR" : "EDITAR" }} COTIZACION</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Product Image -->
        <template v-if="dataImg">
          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="dataImg" alt="img" class="responsive">
          </div>
          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Update Image</vs-button>
            <vs-button type="flat" color="#999" @click="dataImg = null">Remove Image</vs-button>
          </div>
        </template>
        <!-- Unidad -->
        <vs-input label="Unidad" v-model="dataQuotationUnit" class="mt-5 w-full" name="item-name" v-validate="'required'" />

        <!-- STATUS -->
        <vs-select autocomplete v-model="dataStatus" label="Estado" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in status_choices" />
        </vs-select>

        <!-- Cliente -->
        <!--<vs-input label="Direccion" v-model="dataAddress" class="mt-5 w-full" name="item-name" v-validate="'required'" />-->
        <vs-input label="Nombre" v-model="dataClient.name" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <vs-input label="Apellido" v-model="dataClient.lastName" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <vs-input label="RUT" v-mask="'##.###.###-#'" v-model="dataClient.identifier" class="mt-5 w-full" name="item-name" v-validate="'required|rut'" />
        <vs-input label="Telefono" v-model="dataClient.contactData.cellphoneNumber" class="mt-5 w-full" name="item-name" v-validate="'required'" />
        <vs-input label="Email" v-model="dataClient.contactData.email" class="mt-5 w-full" name="item-name" v-validate="'required'" />
      </div>
    </VuePerfectScrollbar>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <!--<vs-button class="mr-6" @click="submitData()">Guardar</vs-button>-->
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

//import VeeValidate from 'vee-validate'

import { Validator } from 'vee-validate';
import { rutValidator } from 'vue-dni';
import Vue from 'vue';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueTheMask from 'vue-the-mask'

Vue.use(vueNumeralFilterInstaller, { locale: 'es' });

Vue.use(VueTheMask);


Validator.extend('rut', rutValidator);

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSidebarActive(val) {
      //console.log(val);
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, project, quotationUnit, client, quotationTime, status, additionals } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataProjectId = project.id
        this.dataQuotationUnit = quotationUnit
        this.dataClient = client
        this.dataQuotationTime = quotationTime
        this.dataStatus = status
        this.dataAdditionals = additionals
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      dataId: null,
      dataName: "",
      dataImg: null,


      dataType: "",
      dataClient: { contactData : {} },
      dataProjectId: "",
      dataQuotationUnit: null,
      dataStatus: "",
      dataQuotationTime: "",
      dataAdditioanls: {},


      dataPrice: 0,

      status_choices: [
        { text: 'Pendiente', value: 'PENDING' },
        { text: 'Emitida', value: 'ISSUED' },
        { text: 'Procesada', value: 'PROCESSED' },
        { text: 'Cotización Expirada', value: 'EXPIRED' }
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
          //this.$validator.reset()
          //this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.dataName;
    }
  },
  methods: {
    initValues() {
      if (this.data.id) return
      this.dataId = null
      this.dataName = ""
      this.dataCategory = null
      this.dataOrder_status = "pending"
      this.dataPrice = 0
      this.dataImg = null
    },
    submitData() {
      //console.log("submitData");
      //this.$validator.validateAll().then(result => {
      //if (this.dataId) {
      const obj = {
        id: this.dataId,
        projectId: this.dataProjectId,
        quotationUnit: this.dataQuotationUnit,
        client: this.dataClient,
        status: this.dataStatus,

        additionals: this.dataAdditionals,
        projectFeatures: this.dataProjectFeatures,
        unitsFeatures: this.dataUnitsFeatures,
        locationFeatures: this.dataLocationFeatures,
        floors: this.dataFloors,
        quotesNumber: this.dataQuotesNumber
      }
      //console.log(this.dataId);
      //console.log(obj);

      if (this.dataId !== null) {
        //console.log("update run");
        this.$store.dispatch("dataListQuotation/updateItem", obj)//.catch(err => { console.error(err) })
      } else {
        //console.log("new run");

        this.$store.dispatch("dataListQuotation/newItem", obj)//.catch(err => { console.error(err) })
      }
      this.isSidebarActiveLocal = false;

      //this.$emit('closeSidebar')
      //this.initValues()
      //}
      //})
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  components: {
    VuePerfectScrollbar,
  }
}

</script>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}

</style>
