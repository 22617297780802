import edocAPI from '@/APIs/edocAPI'
//import projectAPI from '@/APIs/realEstateProjectAPI'
import bookingAPI from '@/APIs/bookingAPI'

export default {

  quotationPdf: function (quotationId) {

    const url = '/retrieve/pdf/';

    const doc = 'quotation_'+quotationId;

    var code = url+doc;

    return code;
    //return edocAPI.get(code);
  },

  bookingPdf: function (quotationId) {

    const url = '/retrieve/pdf/';

    const doc = 'booking_'+quotationId;

    var code = url+doc;

    return code;
    //return edocAPI.get(code);
  },

  saleofferPdf: function (quotationId) {

    const url = '/retrieve/pdf/';

    const doc = 'saleoffer_'+quotationId;

    var code = url+doc;

    return code;
    //return edocAPI.get(code);
  },

  promisePdf: function (promiseId) {

    const url = '/retrieve/pdf/';

    const doc = 'promise_'+promiseId;

    var code = url+doc;

    return code;
    //return edocAPI.get(code);
  },

  retrieveImg: function (identifier) {

    const url = '/projects/uploadImg/';

    const doc = identifier;

    var code = url+doc;

    return code;
    //return edocAPI.get(code);
  },

  bookingDocumentPdf: function (bookingId) {

    const url = '/documentbookings/';

    const doc = 'bookingDocument_'+bookingId;

    var code = url+doc;

    return code;
    //return edocAPI.get(code);
  },

  insuranceDocumentPdf: function (promiseId) {

    const url = '/documentbookings/';

    const doc = 'insuranceDocument_'+promiseId;

    var code = url+doc;

    return code;
    //return edocAPI.get(code);
  },

  generateBookingPdf: function (bookingId) {

    const url = '/pdf';

    let params = {
      params: {
        bookingId: bookingId
      }
    }

    return bookingAPI.get(url, params);
  },

  imgUpload: function (identifier, formData) {

    const url = '/retrieve/uploadImg/'+identifier;

    let headers = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(
          edocAPI.post(url, formData, headers)
        );
      }, 2000);
    });
  },
};
