<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <quotation-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="quotations">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <!-- pageNumber -->
        <div style="width:100px">
          <vs-select autocomplete v-model="pageNumber" label="" class="mt-5 w-full" name="item-category" >
            <vs-select-item  value="0" text="Pag 1"  />
            <vs-select-item  value="1" text="Pag 2"  />
            <vs-select-item  value="2" text="Pag 3"  />
            <vs-select-item  value="3" text="Pag 4"  />
            <vs-select-item  value="4" text="Pag 5"  />
            <vs-select-item  value="5" text="Pag 6"  />
            <vs-select-item  value="6" text="Pag 7"  />
            <vs-select-item  value="7" text="Pag 8"  />
            <vs-select-item  value="8" text="Pag 9"  />
          </vs-select>
        </div>
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        </div>
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ quotations.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : quotations.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=5">
              <span>5</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <template slot="thead">
        <vs-th sort-key="project">Proyecto</vs-th>
        <vs-th sort-key="quoteUnit">Unidad</vs-th>
        <vs-th sort-key="client">Cliente</vs-th>
        <vs-th sort-key="quotationTime">Fecha</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <!--<vs-th>Acciones</vs-th>-->
        <vs-th>Exportar 
          <feather-icon icon="DownloadIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="exportToExcel()" />
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.projectId">
              <p class="product-name font-medium truncate">{{ tr.project.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.quotationUnit }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.client.name }} {{ tr.client.lastName }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.quotationTime | formatDate }}</p>
            </vs-td>
            <vs-td>
              <vs-chip :color="getOrderStatusColor(tr.status)" class="product-order-status">{{translateStatus(tr.status)}}</vs-chip>
              <vs-chip :color="getDaysStatusColor(daysDifference(tr.quotationTime))" class="product-order-status">{{daysDifference(tr.quotationTime)}} dias de vigencia</vs-chip>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="retrievePdf(tr.id)" />Ver PDF
              <br>
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />Ver
            </vs-td>
            <template class="expand-user" slot="expand">
                <div class="vx-row">
                  <!--Datos del Projecto -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Datos del Proyecto
                  </vs-divider>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Nombre:</label>
                    <br>
                    {{tr.project.name}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Cliente:</label>
                    <br>
                    {{ tr.client.name }} {{ tr.client.lastName }}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Unidad:</label>
                    <br>
                    {{tr.quotationUnit}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Adicionales:</label>
                    <br>
                    {{tr.quotationAdditional}} {{tr.quotationWarehouse}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Telefono:</label>
                    <br>
                    {{tr.client.contactData.cellphoneNumber}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Email:</label>
                    <br>
                    {{tr.client.contactData.email}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Valor de la Cotización:</label>
                    <br>
                    UF {{tr.amount| numeral('0, 0.00')}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Observaciones:</label>
                    <br>
                    {{tr.additionalInfo}}
                  </div>
                  <!--Datos Adicionales -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Acciones
                  </vs-divider>
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <label>Ver PDF: </label>
                    <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="retrievePdf(tr.id)" />
                  </div>
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <label>Ver Cotización: </label>
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr)" />
                  </div>
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <label>Eliminar Cotización: </label>
                    <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteProject(tr.id)" />
                  </div>
                </div>
            </template>

          </vs-tr>
        </tbody>
      
      </template>
    </vs-table>
  </div>
</template>

<script>
import EdocService from '@/services/EdocService';
import QuotationViewSidebar from '@/views/ui-elements/data-list/QuotationViewSidebar.vue'
import moduleQuotation from "@/store/quotation/moduleQuotation.js"
import Vue from 'vue';

import vueNumeralFilterInstaller from 'vue-numeral-filter'; 
Vue.use(vueNumeralFilterInstaller, { locale: 'es' });

export default {
  components: {
    QuotationViewSidebar
  },
  data() {
    return {
      pageNumber: localStorage.getItem("pageNumberSelected"),
      pageQuantity: 100,
      selected: [],
      // products: [],
      itemsPerPage: 5,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      
      fileName: "",
      formats:["xlsx", "csv", "txt"] ,
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "Nombre", "Segundo Nombre", "Apellido", "Segundo Apellido", "RUT", "Email", "Telefono", "Celular",
        "Monto", 
        "Projecto", 
        "Estado", 
        "Porcentaje pie", 
        "Unidad", 
        "Adicional", 
        "Bodega", 
        "Numero Cuotas", 
        "Valor UF", 
        "Observaciones", 
        "Fecha"],
      headerVal: [
        "name", "secondName", "lastName", "motherLastName", "identifier", "email", "phoneNumber", "cellphoneNumber",
        "amount", 
        "projectName",
        "statusActual", 
        "footPercentage", 
        "quotationUnit", 
        "quotationAdditional", 
        "quotationWarehouse", 
        "quotesNumber", 
        "uf",
        "additionalInfo", 
        "actualDate"],
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    quotations() {
      return this.$store.state.dataListQuotation.quotations
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.quotations.length
    }
  },
  watch: {
    pageNumber() {
      //this.$log.debug('this: ',this.pageNumber);
      localStorage.setItem("pageNumberSelected",this.pageNumber)

      //window.location.reload();
      setTimeout(function() {
        window.location.reload();
      }, 1000)
    },

  },
	methods: {
    exportToExcel() {
      var quotationsVar = this.quotations
      for (var i = quotationsVar.length - 1; i >= 0; i--) {
        quotationsVar[i].name = quotationsVar[i].client.name
        quotationsVar[i].secondName = quotationsVar[i].client.secondName
        quotationsVar[i].lastName = quotationsVar[i].client.lastName
        quotationsVar[i].motherLastName = quotationsVar[i].client.motherLastName
        quotationsVar[i].identifier = quotationsVar[i].client.identifier
        quotationsVar[i].email = quotationsVar[i].client.contactData.email
        quotationsVar[i].phoneNumber = quotationsVar[i].client.contactData.phoneNumber
        quotationsVar[i].cellphoneNumber = quotationsVar[i].client.contactData.cellphoneNumber;


        let actual = new Date(quotationsVar[i].quotationTime);
        var actualDate = actual.getDate()+"-"+(actual.getMonth()+1)+"-"+actual.getFullYear()+" "+actual.getHours()+":"+actual.getMinutes();
        quotationsVar[i].actualDate = actualDate;
        quotationsVar[i].projectName = quotationsVar[i].project.name

        switch (quotationsVar[i].statusActual) {
          case 'PENDING':
            quotationsVar[i].statusActual = 'Por finalizar'
            break;
          case 'ISSUED':
            quotationsVar[i].statusActual = 'Emitida'
            break;
          case 'PROCESSED':
            quotationsVar[i].statusActual = 'Procesada'
            break;
          case 'EXPIRED':
            quotationsVar[i].statusActual = 'Expirada'
            break;
        } 
      }
      import('@/export/Export2Excel').then(excel => {
        const list = quotationsVar
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        //this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    retrievePdf(quotationId) {
      var code = process.env.VUE_APP_EDOC_API_ROOT + EdocService.quotationPdf(quotationId);
      var win = window.open(code, '_blank');
      win.focus();
    },
		addNewData() {
			this.sidebarData = {}
			this.toggleDataSidebar(true)
		},
		deleteData(id) {
			this.$store.dispatch("dataListQuotation/removeItem", id)//.catch(err => { console.error(err) })
		},
		editData(data) {
			this.sidebarData = data
			this.toggleDataSidebar(true)
		},
    daysDifference (date) {
      var date1 = new Date(date);  
      var date2 = new Date();
      // The number of milliseconds in one day
      var ONEDAY = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);
      // Convert back to days and return
      var value = 10-Math.round(difference_ms/ONEDAY);
      if (value < 0) {
        value = 0;
      }
      return value;
    },
    getDaysStatusColor(days) {
      if (days > 3) return "success"
      if (days < 4) return "danger"
      return "primary"
    },
		getOrderStatusColor(status) {
      if (status == 'PENDING') return "warning"
      if (status == 'ISSUED') return "success"
      if (status == 'PROCESSED') return "primary"
      if (status == 'EXPIRED') return "danger"
      return "primary"
		},
		translateStatus(status) {
      if (status == 'PENDING') return "Por finalizar"
      if (status == 'ISSUED') return "Cotización emitida"
      if (status == 'PROCESSED') return "Procesada"
      if (status == 'EXPIRED') return "Cotización Expirada"
      return "Indeterminado"
		},
		getPopularityColor(num) {
			if (num > 90) return "success"
			if (num > 70) return "primary"
			if (num >= 50) return "warning"
			if (num < 50) return "danger"
			return "primary"
		},
		toggleDataSidebar(val = false) {
			this.addNewDataSidebar = val
		}
	},
	created() {
		if (!moduleQuotation.isRegistered) {
			this.$store.registerModule('dataListQuotation',moduleQuotation)
			moduleQuotation.isRegistered = true
		}
		let pageObject = {
			page: this.pageNumber,
			size: 100
		};
		this.$store.dispatch("dataListQuotation/listQuotations", pageObject)
	},
	mounted() {
		this.isMounted = true;
    if (localStorage.getItem("pageNumberSelected")==null) {
      localStorage.setItem("pageNumberSelected",0);
      this.pageNumber= 0;
    } else {
      this.pageNumber= localStorage.getItem("pageNumberSelected");
    }
		//console.info("quotations");
		//console.info(this.$store.state.dataListQuotation.quotations);
	}
}

</script>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;
        .vs-table--search-input {
          width: 100%;
        }
      }
    }
    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }
    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;
        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      >span {
        display: flex;
        flex-grow: 1;
      }
      .vs-table--search {
        padding-top: 0;
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;
          &+i {
            left: 1rem;
          }
          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;
      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }
    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;
        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }
    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>